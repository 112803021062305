import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
function Optout() {
  return (
    <div>
      <SrrHeader
        heading="Opt-out of Marketing Communications"
        breadcrumb_path1="FORDDIRECT TRUST CENTER"
        breadcrumb_path2="Opt-out of marketing communications"
      />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_FORDDIRECT_SRR_CONFIG_OPTOUT_MYSELF}
          pagId={process.env.REACT_APP_FORDDIRECT_SRR_CONFIG_OPTOUT_MYSELF_PGID}
        />
      </section>
    </div>
  );
}

export default Optout;
