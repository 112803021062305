import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
function OptoutNvVt() {
  return (
    <div>
      <SrrHeader
        heading="Do Not Sell Personal Information"
        breadcrumb_path1="FORDDIRECT TRUST CENTER"
        breadcrumb_path2="Opt Out of Sale (Nevada and Vermont residents only)"
      />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_FORDDIRECT_SRR_CONFIG_OPTOUTNVVM_MYSELF}
          pagId={
            process.env.REACT_APP_FORDDIRECT_SRR_CONFIG_OPTOUTNVVM_MYSELF_PGID
          }
        />
      </section>
    </div>
  );
}

export default OptoutNvVt;
