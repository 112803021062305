import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import TrustCenter from "./components/TrustCenter";
import SubmitRequest from "./components/SubmitRequest";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import Access from "./components/Access";
import Delete from "./components/Delete";
import Optout from "./components/Optout";
import Update from "./components/Update";
import OptoutNvVt from "./components/OptoutNvVt";

import "./App.scss";

function App() {
  return (
    <>
      <Header />
      <Router basename="/ps-forddirect-tc">
        <Switch>
          <Route path="/" exact component={TrustCenter} />
          <Route path="/ps-forddirect-tc" exact component={TrustCenter} />
          <Route path="/submit-request" component={SubmitRequest} />
          <Route path="/access" component={Access} />
          <Route path="/delete" component={Delete} />
          <Route path="/optout" component={Optout} />
          <Route path="/update" component={Update} />
          <Route path="/optoutNvVt" component={OptoutNvVt} />
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;
