import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";
function Delete() {
  return (
    <div>
      <SrrHeader
        heading="Delete Personal Information"
        breadcrumb_path1="FORDDIRECT TRUST CENTER"
        breadcrumb_path2="Request Deletion to your Personal Information"
      />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_FORDDIRECT_SRR_CONFIG_DELTE_MYSELF}
          pagId={process.env.REACT_APP_FORDDIRECT_SRR_CONFIG_DELTE_MYSELF_PGID}
        />
      </section>
    </div>
  );
}

export default Delete;
