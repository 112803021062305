import React, { useState } from "react";
import { Nav, Navbar, NavDropdown, Image } from "react-bootstrap";
import Logo from "../images/logo.png";
import { Container } from "react-bootstrap";

function Header() {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand href="https://www.forddirect.com/" target="_blank">
          <Image
            src={Logo}
            alt="FordDirect logo"
            className="p-2"
            width="160px"
          />
        </Navbar.Brand>
        <div className="flexarea"></div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <NavDropdown
              title="Trust Center"
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item href="/ps-forddirect-tc/submit-request">
                Submit Data Subject Request
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
