import React from "react";

function SrrHeader(props) {
  let url = "";
  console.log("props.breadcrumb_path2", props.breadcrumb_path2);
  switch (props.breadcrumb_path2) {
    case "SUBMIT DATA SUBJECT REQUEST":
      url = "/ps-forddirect-tc/submit-request";
      break;
    case "Request Access to your Personal Information":
      url = "/ps-forddirect-tc/access";
      break;
    case "Request Deletion to your Personal Information":
      url = "/ps-forddirect-tc/delete";
      break;
    case "Update your Personal Information":
      url = "/ps-forddirect-tc/update";
      break;
    case "Opt-out of marketing communications":
      url = "/ps-forddirect-tc/optout";
      break;
    case "Opt Out of Sale (Nevada and Vermont residents only)":
      url = "/ps-forddirect-tc/optoutNvVt";
      break;
    default:
      break;
  }
  return (
    <>
      <div className="heading">
        <div className="container">
          <h1>{props.heading}</h1>
        </div>
      </div>
      <div className="sub-heading bg-gray margin-bottom-30" id="request-form">
        <div className="container">
          <ul className="wirewheel-breadcrumb">
            <li>
              <a className="sub-heading--wirewheel-anchor" href="/">
                {props.breadcrumb_path1}
              </a>
            </li>
            <li>
              <a className="sub-heading--wirewheel-anchor" href={url}>
                {props.breadcrumb_path2}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SrrHeader;
