import React, { useState } from "react";
import { Container, DropdownButton, Dropdown } from "react-bootstrap";
import SrrHeader from "../shared/components/SrrHeader";
export default SubmitRequest;

function SubmitRequest() {
  const [dropdownValue, setDropdownValue] = useState("");
  const [CAVRActive, setCAVRActive] = useState(false);
  const [otherActive, setOtherActive] = useState(false);

  const handleClick = (e) => {
    setDropdownValue(e.target.innerText);
    if (e.target.innerText === "Other State") {
      setCAVRActive(false);
      setOtherActive(true);
    } else {
      setCAVRActive(true);
      setOtherActive(false);
    }
  };
  return (
    <>
      <SrrHeader
        heading="Submit Data Subject Request"
        breadcrumb_path1="FORDDIRECT TRUST CENTER"
        breadcrumb_path2="SUBMIT DATA SUBJECT REQUEST"
      />

      <Container className="submit-request mt-4">
        <section className="mb-3 mt-4">
          <p>
            <strong>
              Please select which state you live in:{" "}
              <span className="text-danger">*</span>
            </strong>
          </p>

          <DropdownButton
            id="dropdown-basic-button"
            title={dropdownValue || "Select Region"}
            className="mb-4 region"
          >
            <Dropdown.Item onClick={handleClick}>California</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>Colorado</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>Connecticut</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>Montana</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>Oregon</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>Texas</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>Utah</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>Virginia</Dropdown.Item>
            <Dropdown.Item onClick={handleClick}>Other State</Dropdown.Item>
          </DropdownButton>
        </section>

        {otherActive && (
          <section style={{ display: "block" }} className="mt-4">
            <p>
              Please select a category below and submit your request to
              FordDirect. You will need to submit a separate request for each
              type of category.
            </p>
            <ul className="list">
              <li className="list-item mt-2">
                <a href="/access" target="_blank">
                  Request Access to your Personal Information
                </a>
              </li>
              <li className="list-item mt-2">
                <a href="/delete" target="_blank">
                  Request Deletion to your Personal Information
                </a>
              </li>
              <li className="list-item mt-2">
                <a href="/optout" target="_blank">
                  Opt-out of marketing communications
                </a>
              </li>
              <li className="list-item mt-2">
                <a href="/update" target="_blank">
                  Update your Personal Information
                </a>
              </li>
              <li className="list-item mt-2">
                <a href="/optoutNvVt" target="_blank">
                  Opt Out of Sale (Nevada and Vermont residents only)
                </a>
              </li>
            </ul>
          </section>
        )}

        {CAVRActive && (
          <section className="mt-4">
            <p>
              <strong>
                <cite>
                  This portal is only used for residents that live in specific
                  states.
                </cite>
              </strong>
            </p>
            <p>
              As stated in FordDirect's{" "}
              <u>
                <a
                  href="https://www.forddirectprivacy.com/"
                  target="_blank"
                  rel="noreferrer"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Privacy Statement"
                >
                  Privacy Statement
                </a>
              </u>
              , if you are a resident of California, Colorado, Connecticut,
              Utah, Texas, Montana, Oregon or Virginia the proper method to
              submit a privacy request is to either use the online web form
              or by calling Ford Motor Company toll free:
            </p>
            <ul>
              <li>
                <strong>Web form: </strong>
                &nbsp;
                <u>
                  <a
                    href="https://www.ford.com/help/privacy/ccpa/"
                    target="_blank"
                    rel="noreferrer"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="https://www.ford.com/help/privacy/ccpa/"
                  >
                    Exercise Your Privacy Rights
                  </a>
                </u>
              </li>
              <li>
                Toll-free telephone number: 1-888-459-1291 (Monday through
                Friday from 5:00 am until 8:00 pm Pacific time or Saturday from
                5:00 am until 5:00 pm Pacific time).
              </li>
            </ul>
          </section>
        )}
      </Container>
    </>
  );
}
